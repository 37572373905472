<template>
  <div class="IC_ChargeDetail" id="IC_ChargeDetailId">
    <div style="text-align: right; margin-bottom: 12px">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="chargeOut">IC Charge</el-button>
    </div>
    <PocTable
      ref="multipleTable"
      class="positionTable"
      show-summary
      id="IC_ChargeDetailIdOne"
      v-loading="queryLoading"
      :data="tableData6"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      stripe
      :max-height="100000000000000000000000"
      size="mini"
      @size-change="pageSizeChange"
      @selection-change="handleSelectionChange"
      @page-current-change="pageChange"
      highlight-current-row
      :header-cell-style="getRowClass"
      :summary-method="getSummaries"
    >
      <el-table-column fixed="left" type="selection" width="55" />
      <el-table-column
        v-for="item in costColumnConfig"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        :align="item.align"
        show-overflow-tooltip
      >
        <!--                :sortable="item.sortable"-->
        <template slot-scope="scope">
          <span>{{
            item.dataType == 'number'
              ? currency(scope.row[item.key])
              : scope.row[item.key]
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Operations"
        width="200"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.status == '1' && scope.row.isHc == '1' && scope.row.toHrbpName != '其它'">
            <span
              class="editSpan"
              v-permission>已接受</span>
          </div>
          <div v-else-if="scope.row.status == '1' && scope.row.isHc == '0'">
            <span class="editSpan" role="adjust" v-permission style="cursor: pointer" @click="edit(scope.row)">调整</span>
            <el-popconfirm
              title="确定删除吗？"
              style="margin-left: 10px;cursor: pointer"
              v-permission
              role="detailsdelete"
              @confirm="deleteOps(scope.row)"
            >
            <span class="editSpan" style="cursor: pointer" slot="reference">删除</span>
            </el-popconfirm>
          </div>
          <div v-else-if="scope.row.status == '1' && scope.row.isHc == '1' && scope.row.toHrbpName == '其它'">
            <span class="editSpan" role="adjust" v-permission style="cursor: pointer" @click="edit(scope.row)">调整</span>
            <el-popconfirm
              title="确定删除吗？"
              style="margin-left: 10px;cursor: pointer"
              v-permission
              role="detailsdelete"
              @confirm="deleteOps(scope.row)"
            >
              <span class="editSpan" style="cursor: pointer" slot="reference">删除</span>
            </el-popconfirm>
          </div>
          <div
            v-else-if="
              (!scope.row.isToBp && scope.row.status == '-1') ||
              (!scope.row.isToBp && scope.row.status == '0') ||
              (!scope.row.isToBp && scope.row.status == '2')
            "
          >
            <span
              class="editSpan"
              style="cursor: pointer"
              @click="edit(scope.row)"
              role="adjust"
              v-permission
              v-if="scope.row.status == '2' || scope.row.status == '-1'"
              >调整</span>

            <span
              class="editSpan"
              v-if="scope.row.status == '0'"
              v-permission>已提交</span>

            <span
              class="editSpan"
              style="margin-left: 10px;cursor: pointer"
              v-permission
              role="submit"
              v-if="scope.row.status == '2' || scope.row.status == '-1'"
              @click="editSubmit(scope.row)">提交</span>

            <el-popconfirm
              title="确定删除吗？"
              style="margin-left: 10px;cursor: pointer"
              v-permission
              role="detailsdelete"
              @confirm="deleteOps(scope.row)"
              v-if="scope.row.status == '2' || scope.row.status == '-1'"
            >
              <span class="editSpan" style="cursor: pointer" slot="reference"
                >删除</span
              >
            </el-popconfirm>

<!--            <span-->
<!--              class="editSpan pointer"-->
<!--              role="op131ChangeHistory"-->
<!--              v-permission-->
<!--              @click.stop="editHistory(scope.row)"-->
<!--              >调整历史-->
<!--            </span>-->
          </div>
          <div
            v-else-if="scope.row.isToBp"
          >
            <span
              type="text"
              style="cursor: pointer"
              class="editSpan"
              @click="chat(scope.row)"
              >查看</span
            >
            <span
              v-permission
              type="text"
              style="margin-left: 10px;cursor: pointer"
              class="editSpan"
              @click="accept(scope.row)"
              role="accept"
              >接受</span
            >
            <span
              v-permission
              role="reject"
              type="text"
              style="margin-left: 10px;cursor: pointer"
              class="editSpan"
              @click="recive(scope.row)"
              >驳回</span
            >
          </div>
        </template>
      </el-table-column>
      <div class="bottomTable"></div>
    </PocTable>
    <PocTable
      ref="multipleTable"
      class="displayTable"
      id="IC_ChargeDetailIdTwo"
      show-summary
      v-loading="queryLoading"
      :data="tableData6"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      stripe
      :max-height="100000000000000000000000"
      size="mini"
      @selection-change="handleSelectionChange1"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      highlight-current-row
      :header-cell-style="getRowClass"
      :summary-method="getSummariesHc"
    >
      <el-table-column fixed="left" type="selection" width="55" />
      <el-table-column
        v-for="item in hcColumnConfig"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        :align="item.align"
        show-overflow-tooltip
      >
        <!--           :sortable="item.sortable"-->
        <template slot-scope="scope">
          <span>{{
            item.dataType == 'number'
              ? currency(scope.row[item.key])
              : scope.row[item.key]
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Operations"
        width="200"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.status == '1' && scope.row.isHc == '1' && scope.row.toHrbpName != '其它'">
            <span
              class="editSpan"
              v-permission>已接受</span>
          </div>
          <div v-else-if="scope.row.status == '1' && scope.row.isHc == '0'">
            <span class="editSpan" role="adjust" v-permission style="cursor: pointer" @click="edit(scope.row)">调整</span>
            <el-popconfirm
              title="确定删除吗？"
              style="margin-left: 10px;cursor: pointer"
              v-permission
              role="detailsdelete"
              @confirm="deleteOps(scope.row)"
            >
              <span class="editSpan" style="cursor: pointer" slot="reference">删除</span>
            </el-popconfirm>
          </div>
          <div v-else-if="scope.row.status == '1' && scope.row.isHc == '1' && scope.row.toHrbpName == '其它'">
            <span class="editSpan" role="adjust" v-permission style="cursor: pointer" @click="edit(scope.row)">调整</span>
            <el-popconfirm
              title="确定删除吗？"
              style="margin-left: 10px;cursor: pointer"
              v-permission
              role="detailsdelete"
              @confirm="deleteOps(scope.row)"
            >
              <span class="editSpan" style="margin-left: 10px;cursor: pointer" slot="reference">删除</span>
            </el-popconfirm>
          </div>
          <div
            v-else-if="
              (!scope.row.isToBp && scope.row.status == '-1') ||
              (!scope.row.isToBp && scope.row.status == '0') ||
              (!scope.row.isToBp && scope.row.status == '2')
            "
          >
            <span
              class="editSpan"
              style="cursor: pointer"
              @click="edit(scope.row)"
              role="adjust"
              v-permission
              v-if="scope.row.status == '2' || scope.row.status == '-1'"
              >调整</span>

            <span
              class="editSpan"
              v-if="scope.row.status == '0'"
              v-permission>已提交</span>

             <span
               class="editSpan"
               style="margin-left: 10px;cursor: pointer"
               v-permission
               role="submit"
               v-if="scope.row.status == '2' || scope.row.status == '-1'"
               @click="editSubmit(scope.row)">提交</span>

            <el-popconfirm
              title="确定删除吗？"
              style="margin-left: 10px;cursor: pointer"
              v-permission
              role="detailsdelete"
              @confirm="deleteOps(scope.row)"
              v-if="scope.row.status == '2' || scope.row.status == '-1'"
            >
              <span class="editSpan" style="cursor: pointer" slot="reference"
                >删除</span
              >
            </el-popconfirm>
<!--            <span-->
<!--              class="editSpan pointer"-->
<!--              role="op131ChangeHistory"-->
<!--              v-permission-->
<!--              @click.stop="editHistory(scope.row)"-->
<!--              >调整历史-->
<!--            </span>-->
          </div>
          <!--                     -->
          <div
            v-else-if="scope.row.isToBp"
          >
            <span class="editSpan" type="text" @click="chat(scope.row)"
              >查看</span
            >
            <span style="margin-left: 10px;cursor: pointer" role="accept" v-permission class="editSpan" type="text" @click="accept(scope.row)"
              >接受</span
            >
            <span style="margin-left: 10px;cursor: pointer" role="reject" v-permission class="editSpan" type="text" @click="recive(scope.row)"
              >驳回</span
            >
          </div>
        </template>
      </el-table-column>
    </PocTable>
    <addIcChargeModal
      v-bind:periods="period"
      v-bind:versionId="id"
      v-bind:editType="editType"
      :is-summary="isSummary"
      v-if="newIcChargeFlag"
      @closeModal="closeModal"
    />
    <chargeOutDialog ref="chargeOutDialogRef" @close="filterQuery" />
  </div>
</template>

<script>
import chargeOutDialog from '@/views/salaryManagement/components/modal/ChargeOutDialog/ChargeOutDialog.vue'
import currency from 'currency.js'
import {
  changeOp131Year,
  ops131CostTableDataConfig,
  ops131HcTableDataConfig
} from '@/views/salaryManagement/editionData/ops131tableData'
import addIcChargeModal from '@/views/salaryManagement/components/modal/addIcChargeModal.vue'
import addFreelancerModal from '@/views/salaryManagement/components/modal/addFreeLancherModal.vue'

import {
  accept,
  eleteOp131Op151Op152ById,
  getIcChargeOp131List,
  getOp111List,
  getOp131List,
  op131Submit,
  op131TotalCost,
  reject
} from '@/api/pctrakerop131'
import { getItem, setItem } from '@/utils/storage'
import { titleCase } from '@/utils/util'

const { Vuex } = $PCommon
const { mapGetters } = Vuex

export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    addIcChargeModal,
    addFreelancerModal,
    chargeOutDialog
  },
  data() {
    return {
      period: {},
      queryLoading: false,
      tableData6: [],
      costColumnConfig: ops131CostTableDataConfig,
      hcColumnConfig: ops131HcTableDataConfig,
      manageShow: false,
      jumpParams: {},
      selectedItems: [],
      newIcChargeFlag: false,
      editType: '',
      id: '',
      summaries: {
        totalCost: {},
        hcTotalCost: {}
      },
      filterConfig: {},
      isSummary: false,
      selectVal: [],
      selectVal1: []
    }
  },
  computed: {
    columnOptions() {}
  },
  created() {
    this.id = this.$route.query.id
    this.editType = 'new'
    changeOp131Year()
    this.filterQuery()
  },
  mounted() {
    let bodyWrapper = document.getElementById('IC_ChargeDetailIdOne')
    let bodyWrapper2 = document.getElementById('IC_ChargeDetailIdTwo')
    bodyWrapper = bodyWrapper
      .querySelector('.el-table')
      .querySelector('.el-table__body-wrapper')
    bodyWrapper2 = bodyWrapper2
      .querySelector('.el-table')
      .querySelector('.el-table__body-wrapper')
    bodyWrapper.addEventListener('scroll', function (event) {
      bodyWrapper2.scrollLeft = bodyWrapper.scrollLeft
    })
    bodyWrapper2.addEventListener('scroll', function (event) {
      bodyWrapper.scrollLeft = bodyWrapper2.scrollLeft
    })
  },
  methods: {
    accept(val) {
      const data = {
        id: val.id
      }
      accept(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('接受成功')
          this.filterQuery()
        }
      })
    },
    editSubmit(val) {
      const data = {
        id: val.id
      }
      op131Submit(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('提交成功')
          this.filterQuery()
        }
      })
    },
    async getOp1512Totalcost() {
      const data1 = {
        id: this.id
      }
      op131TotalCost(data1).then((res) => {
        if (res && res.code === 200 && res.data) {
          Object.keys(res.data).forEach((item) => {
            if (item.includes('Cost')) {
              this.summaries.totalCost[item] = res.data[item]
            }
            if (item.includes('Hc')) {
              this.summaries.hcTotalCost[item] = res.data[item]
            }
          })
        }
      })
    },
    recive(val) {
      const data = {
        id: val.id
      }
      reject(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('驳回成功')
          this.filterQuery()
        }
      })
    },
    chat(val) {
      this.period = val
      this.editType = 'chat'
      this.$store.dispatch('ChargeOut/changeChargeType', 'chat')
      // this.newIcChargeFlag = true
      const newDara = JSON.parse(val.icChargeFront)
      this.$store
        .dispatch('ChargeOut/formatCellDisplay', newDara)
        .then((res) => {
          if (res) {
            console.log(res)
            this.$store.dispatch('ChargeOut/changeTableData', res)
            this.$store.dispatch('ChargeOut/changeActive', 1)
            const data = {
              buttonActive: '',
              editionId: this.id,
              id: this.id,
              showButtonCall: true
            }
            this.$refs.chargeOutDialogRef.show(data)
          }
        })
    },
    chargeOut() {
      this.$store.dispatch('ChargeOut/changeChargeType', 'add')
      const data = {
        id:'',
        editionId: this.id,
        periodId : this.id
      }
      this.$refs.chargeOutDialogRef.show(data)
    },
    editHistory(row) {
      setItem('pcTrackerOp111Row__yc', row.id)
      const tabTwoType = 'op131'
      const id = row.id
      if (id) {
        this.$router.push({
          path: '/pcTracker/editHistory',
          query: {
            tabTwoType,
            id,
            target: 'op112'
          }
        })
      } else {
        this.$message.error('缺少id')
      }
    },
    currency(value) {
      return currency(value, { symbol: '' }).format()
    },
    newIcCharge() {
      this.period = {}
      this.newIcChargeFlag = true
    },
    getSummaries(param) {
      //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
      const { columns, data } = param
      const len = columns.length
      const sums = []
      let self = this
      columns.forEach((column, index) => {
        //如果是第一列，则最后一行展示为“总计”两个字
        if (index === 1) {
          sums[index] = 'Total Intercompany Recharge (Cost)'
          //如果是最后一列，索引为列数-1，则显示计算总和
        } else if (6 < index && index < len - 1) {
          if (this.selectVal.length > 0) {
            const values = this.selectVal.map((item) =>
              Number(item[column.property])
            )
            if (!values.every((value) => isNaN(value))) {
              sums[index] = self.currency(
                values.reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
              )
            } else {
              sums[index] = 'N/A'
            }
          } else {
            if (column.property.substring(0, 3) === 'tot') {
              sums[index] = this.currency(
                parseFloat(this.summaries.totalCost['totalCost']) || 0
              )
            } else {
              let str = 'totalCost' + titleCase(column.property.substring(0, 3))
              sums[index] = this.currency(
                parseFloat(this.summaries.totalCost[str]) || 0
              )
            }
          }
          //如果是除了第一列和最后一列的其他列，则显示为空
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    handleSelectionChange1(val) {
      this.selectVal1 = val
    },
    getSummariesHc(param) {
      //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
      const { columns, data } = param
      const len = columns.length
      const sums = []
      let self = this
      columns.forEach((column, index) => {
        //如果是第一列，则最后一行展示为“总计”两个字
        if (index === 1) {
          sums[index] = 'Total Intercompany Recharge (HC)'
          //如果是最后一列，索引为列数-1，则显示计算总和
        } else if (6 < index && index < len - 1) {
          if (this.selectVal1.length > 0) {
            const values = this.selectVal1.map((item) =>
              Number(item[column.property])
            )
            if (!values.every((value) => isNaN(value))) {
              sums[index] = self.currency(
                values.reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
              )
            } else {
              sums[index] = 'N/A'
            }
          } else {
            if (column.property.substring(0, 3) === 'tot') {
              sums[index] = this.currency(
                parseFloat(this.summaries.hcTotalCost['totalHc']) || 0
              )
            } else {
              let str = 'totalHc' + titleCase(column.property.substring(0, 3))
              sums[index] = this.currency(
                parseFloat(this.summaries.hcTotalCost[str]) || 0
              )
            }
          }
          //如果是除了第一列和最后一列的其他列，则显示为空
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    edit(val) {
      this.period = val
      this.editType = 'edit'
      this.$store.dispatch('ChargeOut/changeChargeType', 'edit')
      const newDara = JSON.parse(val.icChargeFront)
      this.$store
        .dispatch('ChargeOut/formatCellDisplay', newDara)
        .then((res) => {
          if (res) {
            this.$store.dispatch('ChargeOut/changeTableData', res)
            this.$store.dispatch('ChargeOut/changeActive', 1)
            const data = {
              buttonActive: '',
              id: val.id,
              showButtonCall: true,
              periodId : this.id

            }
            this.$refs.chargeOutDialogRef.show(data)
          }
        })
    },
    deleteOps(val) {
      const data = {
        id: val.id,
        type: 'op131'
      }
      eleteOp131Op151Op152ById(data).then((res) => {
        if (res && res.code === 200) {
          this.filterQuery()
        }
      })
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === this.tableData6.length - 2) {
        return 'warning-row'
      }
    },
    closeModal() {
      this.newIcChargeFlag = false
      this.filterQuery()
    },
    getRowClass() {
      return 'background:#F1EAE0'
    },
    filterQuery() {
      this.pageConfig.pageNum = 1
      this.getOp1512Totalcost()
      this.queryApi()
    },
    // 获取基础数据维护的列表
    async queryApi() {
      const data = {
        id: this.id,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum,
        total: this.pageConfig.total
      }
      this.queryLoading = true
      await getIcChargeOp131List(data).then((res) => {
        //jacob添加
        this.tableData6 = res.data.list ? res.data.list : []
        this.pageConfig.total = res.data.total || 0
        this.point()
      })
      this.queryLoading = false
    },
    point() {
      let pcTrackerOp111Row__yc = getItem('pcTrackerOp111Row__yc')
      if (pcTrackerOp111Row__yc) {
        let row = this.tableData6.find(
          (item) => Number(item.id) === Number(pcTrackerOp111Row__yc)
        )
        if (row) {
          this.$refs.multipleTable.setCurrentRow(row, true)
        }
      }
    },
    handleSelectionChange(val) {
      this.selectVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.IC_ChargeDetail {
  background: white;
  padding: 20px;
  ::v-deep .el-table__body-wrapper {
    z-index: 2 !important;
  }
  :deep(.displayTable) {
    margin-top: 20px;
    //.el-table__header {
    //  display: none;
    //}
  }
  :deep(.positionTable) {
    position: relative;
    .el-table__header {
      //position: fixed;
    }
  }
  :deep(.el-table) {
    .el-table__header-wrapper {
      .el-table__cell {
        .cell {
          font-size: 10px;
          transform: scale(0.9);
          -webkit-transform: scale(0.9);
        }
      }
    }
    .bottomTable {
      height: 30px;
    }
  }
  :deep(.buttonActive1) {
    height: 15px;
    border-radius: 2px;
    margin-right: 6px;
    span {
      font-size: 10px;
      display: block;
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: white;
      position: relative;
      top: -5.5px;
    }
  }
  .positionIc {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }
}
.buttonBigunActive {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
  padding: 7px 20px;
}
.pointer {
  cursor: pointer;
}
.editSpan {
  font-size: 12px;
  color: #ba9765;
}
</style>
